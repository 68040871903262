<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <h3>Installa APP</h3>
                <p>Per installare l'APP di MGN MultiService sui nuovi dispositivi,<br>
                o per aggiornare i dispositivi alla versione {{ lastAppVersion }},<br>
                scansiona il codice QR sottostante o clicca il pulsante "Download".</p>
                <img :src="qrCodeSource" class="qrCode" />

                <button type="button"
                class="btn btn-primary btn-lg"
                @click="download()">
                    Download
                </button>
            </div>
        </div>
    </div>
  </section>
</template>

<style scoped>
a.action {
    margin-right:5px!important;
}
img.qrCode {
    max-width: 240px;
    margin:     0 30px;
}
</style>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Vue from 'vue'
import VueMoment from 'vue-moment'

Vue.use(VueMoment)

export default {
    components: {
        VueTagsInput,
    },

    // #region Properties
    data () {
        return {
            qrCodeSize: 300
        }
    },
    filters: {

    },
    computed: {
        qrCodeSource() {
            // QRCode generated by google APIs
            // see  https://developers.google.com/chart/infographics/docs/qr_codes
            return 'https://chart.googleapis.com/chart?chs=' + this.qrCodeSize + 'x' + this.qrCodeSize + '&cht=qr&choe=UTF-8&chl=' + encodeURI(this.$root.apkUrl)
        },
        lastAppVersion() {
            // returns the last APP release version number
            return process.env.MIX_APP_VERSION
        }
    },
    // #endregion Properties

    methods: {
        download() {
            window.open(this.$root.apkUrl)
        }
    },

    // #region Component Life Cycle
    mounted() {
    },
    created() {
        this.$Progress.start();
        this.$Progress.finish();
    },
    // #endregion Component Life Cycle
}
</script>
