<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <h3>Pagina non trovata</h3>
                    <img src="/images/404.png" alt="pagina non trovata">
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
div.col-md-12 {
    text-align: center;
}
h3 {
    text-align: center;
}
img {
    max-width: 320px;
    margin:auto!important;
}
</style>

<script>
    export default {
        mounted() {

        }
    }
</script>
