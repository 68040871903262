<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h3>Piani orari</h3>

          <div class="card" v-if="!isLoading">
            <div class="card-header">
              <div class="card-tools col-12 text-right">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  title="Aggiungi un piano orari"
                  @click="onAddClick"
                >
                  <i class="fa fa-plus-square"></i>
                  Aggiungi
                </button>
              </div>
            </div>

            <div class="card-body table-responsive p-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Lunedì</th>
                    <th>Martedì</th>
                    <th>Mercoledì</th>
                    <th>Giovedì</th>
                    <th>Venerdì</th>
                    <th>Sabato</th>
                    <th>Domenica</th>
                    <th>Totale ore</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in workingPlans" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.monday / 60 }}</td>
                    <td>{{ item.tuesday / 60 }}</td>
                    <td>{{ item.wednesday / 60 }}</td>
                    <td>{{ item.thursday / 60 }}</td>
                    <td>{{ item.friday / 60 }}</td>
                    <td>{{ item.saturday / 60 }}</td>
                    <td>{{ item.sunday / 60 }}</td>
                    <td>{{ item.total_working_hours }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-footer">
              <pagination
                :data="apiResponse"
                @pagination-change-page="loadWorkingPlans"
                :limit="10"
              ></pagination>
            </div>
          </div>
        </div>
      </div>

      <modal id="addNewModal" title="Aggiungi Piano orari">
        <template #content>
          <form id="addNewForm" @submit.prevent="onAddNewSaveClick">
            <div
              class="form-group"
              v-for="formGroup in formGroups"
              :key="formGroup.name"
            >
              <label
                class="d-block"
                :class="{
                  'is-invalid': form.errors.has(formGroup.name),
                  'd-flex align-items-baseline justify-content-between':
                    formGroup.horizontal,
                }"
              >
                {{ formGroup.label }}
                <input
                  v-model="form[formGroup.name]"
                  :type="formGroup.type"
                  :name="formGroup.name"
                  step="0.5"
                  class="form-control"
                  :class="{
                    'is-invalid': form.errors.has(formGroup.name),
                    'w-25': formGroup.horizontal,
                  }"
                />
              </label>
              <has-error :form="form" :field="formGroup.name"></has-error>
            </div>

            <div class="d-flex align-items-baseline justify-content-between">
              <h5>Totale ore</h5>
              <h5 class="w-25 text-left">{{ formTotal }}</h5>
            </div>
          </form>
        </template>
        <template #footer>
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Annulla
          </button>
          <button type="submit" form="addNewForm" class="btn btn-success">
            Salva
          </button>
        </template>
      </modal>
    </div>
  </section>
</template>

<script>
import Modal from "../Modal.vue";
export default {
  components: {
    Modal,
  },

  data() {
    return {
      isLoading: false,
      apiResponse: null,
      form: new Form({
        name: "",
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
      }),

      formGroups: [
        { name: "name", label: "Nome", type: "text", horizontal: false },
        { name: "monday", label: "Lunedì", type: "number", horizontal: true },
        { name: "tuesday", label: "Martedì", type: "number", horizontal: true },
        {
          name: "wednesday",
          label: "Mercoledì",
          type: "number",
          horizontal: true,
        },
        {
          name: "thursday",
          label: "Giovedì",
          type: "number",
          horizontal: true,
        },
        { name: "friday", label: "Venerdì", type: "number", horizontal: true },
        { name: "saturday", label: "Sabato", type: "number", horizontal: true },
        { name: "sunday", label: "Domenica", type: "number", horizontal: true },
      ],
    };
  },

  computed: {
    workingPlans() {
      if (!this.apiResponse?.data) return [];
      return this.apiResponse?.data;
    },

    formTotal() {
      const days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      return days.reduce((acc, curr) => (acc += +this.form[curr]), 0);
    },
  },

  created() {
    this.loadWorkingPlans();
  },

  methods: {
    async loadWorkingPlans(page = 1) {
      this.isLoading = true;
      try {
        const r = await axios.get("api/working-plans", { params: { page } });
        this.apiResponse = r.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    onAddClick() {
      this.form.reset();
      this.form.clear();
      $("#addNewModal").modal("show");
    },

    async onAddNewSaveClick() {
      this.$Progress.start();
      try {
        await this.form.post("api/working-plans");
        this.loadWorkingPlans();
        $("#addNewModal").modal("hide");
        this.form.reset();
      } catch (err) {
        console.error(err);
        if (err?.response?.status == 422) {
          return;
        }
        Toast.fire({
          icon: "error",
          title: "Si è verificato un errore! Prego, riprova",
        });
        this.$Progress.fail();
      } finally {
        this.$Progress.finish();
      }
    },
  },
};
</script>
