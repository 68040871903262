var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Piani orari")]),_vm._v(" "),(!_vm.isLoading)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-tools col-12 text-right"},[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","title":"Aggiungi un piano orari"},on:{"click":_vm.onAddClick}},[_c('i',{staticClass:"fa fa-plus-square"}),_vm._v("\n                Aggiungi\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"card-body table-responsive p-0"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.workingPlans),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.monday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.tuesday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.wednesday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.thursday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.friday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.saturday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.sunday / 60))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.total_working_hours))])])}),0)])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('pagination',{attrs:{"data":_vm.apiResponse,"limit":10},on:{"pagination-change-page":_vm.loadWorkingPlans}})],1)]):_vm._e()])]),_vm._v(" "),_c('modal',{attrs:{"id":"addNewModal","title":"Aggiungi Piano orari"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"addNewForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onAddNewSaveClick($event)}}},[_vm._l((_vm.formGroups),function(formGroup){return _c('div',{key:formGroup.name,staticClass:"form-group"},[_c('label',{staticClass:"d-block",class:{
                'is-invalid': _vm.form.errors.has(formGroup.name),
                'd-flex align-items-baseline justify-content-between':
                  formGroup.horizontal,
              }},[_vm._v("\n              "+_vm._s(formGroup.label)+"\n              "),((formGroup.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[formGroup.name]),expression:"form[formGroup.name]"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.form.errors.has(formGroup.name),
                  'w-25': formGroup.horizontal,
                },attrs:{"name":formGroup.name,"step":"0.5","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[formGroup.name])?_vm._i(_vm.form[formGroup.name],null)>-1:(_vm.form[formGroup.name])},on:{"change":function($event){var $$a=_vm.form[formGroup.name],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, formGroup.name, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, formGroup.name, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, formGroup.name, $$c)}}}}):((formGroup.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[formGroup.name]),expression:"form[formGroup.name]"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.form.errors.has(formGroup.name),
                  'w-25': formGroup.horizontal,
                },attrs:{"name":formGroup.name,"step":"0.5","type":"radio"},domProps:{"checked":_vm._q(_vm.form[formGroup.name],null)},on:{"change":function($event){return _vm.$set(_vm.form, formGroup.name, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[formGroup.name]),expression:"form[formGroup.name]"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.form.errors.has(formGroup.name),
                  'w-25': formGroup.horizontal,
                },attrs:{"name":formGroup.name,"step":"0.5","type":formGroup.type},domProps:{"value":(_vm.form[formGroup.name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, formGroup.name, $event.target.value)}}})]),_vm._v(" "),_c('has-error',{attrs:{"form":_vm.form,"field":formGroup.name}})],1)}),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_c('h5',[_vm._v("Totale ore")]),_vm._v(" "),_c('h5',{staticClass:"w-25 text-left"},[_vm._v(_vm._s(_vm.formTotal))])])],2)]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("\n          Annulla\n        ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit","form":"addNewForm"}},[_vm._v("\n          Salva\n        ")])]},proxy:true}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nome")]),_vm._v(" "),_c('th',[_vm._v("Lunedì")]),_vm._v(" "),_c('th',[_vm._v("Martedì")]),_vm._v(" "),_c('th',[_vm._v("Mercoledì")]),_vm._v(" "),_c('th',[_vm._v("Giovedì")]),_vm._v(" "),_c('th',[_vm._v("Venerdì")]),_vm._v(" "),_c('th',[_vm._v("Sabato")]),_vm._v(" "),_c('th',[_vm._v("Domenica")]),_vm._v(" "),_c('th',[_vm._v("Totale ore")])])])}]

export { render, staticRenderFns }